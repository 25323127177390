import React from 'react';
import { addDays, differenceInMilliseconds, format, isAfter, isBefore, set, startOfDay } from 'date-fns';
import { twMerge } from 'tailwind-merge';
import { SHOW_SHOWTIME_PLAY_BUTTON, TIMESCALE } from 'tvm-config';
import { createDate, isCurrent, isCurrentOrPrevious } from '@utils/dateHelpers';
import { useTvGuideStore } from '@src/stores/tvguide';
import { TvChannel, TvChannelShowtime } from '@lib/graphql/generated';
import { IconPlayTvguide } from '@assets/icon-play-tvguide';

export interface TimelineProgramEntryProps {
  entry: TvChannelShowtime;
  channel: TvChannel;
  currentTime: Date;
}

export const TimelineProgramEntry = ({ entry, channel, currentTime }: TimelineProgramEntryProps) => {
  const activeGuideDay = useTvGuideStore((state) => state.activeGuideDay);
  const setOpenEntryData = useTvGuideStore((state) => state.setOpenEntryData);
  const startTime = createDate(entry.start).getTime();
  const endTime = createDate(entry.stop).getTime();

  if (
    isBefore(createDate(entry.start), startOfDay(activeGuideDay)) ||
    isAfter(
      createDate(entry.start),
      set(addDays(activeGuideDay, 1), { hours: 6, minutes: 0, seconds: 0, milliseconds: 0 }),
    )
  )
    return null;

  const offset = differenceInMilliseconds(createDate(entry.start), startOfDay(activeGuideDay)) / 1000 / 60;
  if (!offset) return null;

  const duration = (endTime - startTime) / 1000 / 60; // duration in minutes
  const isCurrentEntry = isCurrent(entry.start, entry.stop, currentTime);

  return (
    <button
      className="content-auto hover:content-visible group absolute h-full flex-none px-0.5 hover:z-10 focus:outline-none"
      style={{
        left: `${offset * TIMESCALE}px`,
        width: `${duration * TIMESCALE}px`,
      }}
      onClick={() => {
        setOpenEntryData(entry, channel);
      }}
    >
      <div
        className={twMerge(
          'pointer-events-none -left-10 grid h-full justify-start overflow-clip rounded border border-gray-650/25 bg-gradient-to-t from-gray-840 to-gray-700 transition-colors duration-300 group-hover:z-20 group-hover:w-max group-hover:min-w-max group-hover:border-primary',
          isCurrentEntry && 'from-gray-600 to-gray-600',
        )}
        style={{
          width: `${duration * TIMESCALE - 4}px`,
        }}
      >
        <div className="sticky left-0 grid h-full grid-rows-[1fr,auto] items-stretch text-clip px-3 py-2">
          <div className="relative block h-full text-clip text-left text-sm font-bold tracking-2px text-gray-400">
            <div
              className={twMerge(
                'absolute left-0 top-0 line-clamp-1 min-w-max group-hover:relative group-hover:min-w-72',
                isCurrentEntry && 'text-primary',
              )}
            >
              {SHOW_SHOWTIME_PLAY_BUTTON && isCurrentOrPrevious(entry.start, new Date().getTime()) && (
                <IconPlayTvguide classProps={{ root: 'inline mr-1.5 min-w-0' }} />
              )}
              <span className={isCurrentEntry ? 'text-primary' : ''}></span>
              {format(createDate(entry.start), 'HH:mm')}&nbsp;-&nbsp;{format(createDate(entry.stop), 'HH:mm')}
            </div>
          </div>
          <div className="relative block h-full text-clip text-left text-base lg:text-xl">
            <div className="absolute bottom-0 left-0 line-clamp-1 min-w-max group-hover:relative group-hover:min-w-72">
              {entry.title}
            </div>
          </div>
        </div>
      </div>
    </button>
  );
};
