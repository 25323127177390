import React, { useEffect, useMemo, useState } from 'react';
import dynamic from 'next/dynamic';
import { Image, PropsWithClassProps } from '@vgn-medien-holding/vgn-fe-components';
import { format } from 'date-fns';
import { twMerge } from 'tailwind-merge';
import { FallbackCard } from '@components/atoms/FallbackCard/FallbackCard';
import { createDate, isCurrent } from '@utils/dateHelpers';
import { useTvGuideStore } from '@src/stores/tvguide';
import { getChannelLogo } from '@src/utils/sortedChannels';
import { TvChannel, TvChannelShowtime } from '@lib/graphql/generated';

const ShowtimeProgress = dynamic(() =>
  import('@components/molecules/ShowtimeProgress/ShowtimeProgress').then((mod) => mod['ShowtimeProgress']),
);

export interface FeaturedProgramEntryProps extends PropsWithClassProps {
  channel: TvChannel;
  showtime?: TvChannelShowtime;
  type: 'current' | 'primetime';
  currentTime?: Date;
  isFirst?: boolean;
}

export const FeaturedProgramEntry = ({
  channel,
  showtime,
  type = 'current',
  isFirst,
  currentTime,
  classProps,
}: FeaturedProgramEntryProps) => {
  const setOpenEntryData = useTvGuideStore((state) => state.setOpenEntryData);
  const [initiallyRendered, setInitiallyRendered] = useState(false);

  const isCurrentEntry = type === 'primetime' && isCurrent(showtime.start, showtime.stop, currentTime);
  const logoPath = useMemo(() => getChannelLogo(channel?.id), [channel.id]);

  useEffect(() => {
    setTimeout(() => {
      setInitiallyRendered(true);
    }, 300);
  }, []);

  return (
    <div
      className={twMerge(
        'h-48 opacity-0 transition-opacity duration-150',
        initiallyRendered && showtime && 'opacity-100',
      )}
    >
      {initiallyRendered && showtime && (
        <button
          className={twMerge(
            'mb-6 block w-full cursor-pointer rounded-lg border border-gray-600 bg-gray-600 p-4 text-left hover:border-primary',

            classProps?.root,
          )}
          onClick={() => {
            setOpenEntryData(showtime, channel);
          }}
        >
          <div className="relative aspect-video w-full">
            {showtime?.image?.url ? (
              <Image
                src={showtime.image.url}
                alt={showtime.title}
                fill
                sizes="256px"
                copyright=""
                classProps={{ root: 'rounded-b-sm rounded-t-lg' }}
                priority={isFirst && type === 'current'}
              />
            ) : (
              <FallbackCard />
            )}
            {!!channel && type === 'primetime' && (
              <figure className="absolute right-0 top-0 pr-3 pt-2">
                <Image
                  src={logoPath}
                  alt={channel?.name}
                  width={32}
                  height={18}
                  copyright=""
                  placeholder="empty"
                  classProps={{ root: 'h-auto max-h-full w-8 object-fit', container: 'flex items-center' }}
                />
              </figure>
            )}
            <ShowtimeProgress showtime={showtime} progressClassProps={{ root: '-mt-1' }} />
          </div>
          <div
            className={twMerge(
              'mt-2 text-xs font-bold tracking-2px',
              type === 'current' || isCurrentEntry ? 'text-primary' : 'text-gray-400',
            )}
          >
            {format(createDate(showtime.start), 'HH:mm')}&nbsp;-&nbsp;
            {format(createDate(showtime.stop), 'HH:mm')}
          </div>
          <div className="mt-1 line-clamp-1 break-all text-white">{showtime.title}</div>
        </button>
      )}
    </div>
  );
};
